import React from 'react'

// import Layout from '../components/layout'
// import SEO from '../components/seo'

const NotFoundPage = () => (
  <div>
    Not found.
  </div>
)

export default NotFoundPage
